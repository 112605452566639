import GLightBox from 'glightbox'

if (window.document.readyState === 'complete') {
  init()
} else {
  window.addEventListener('load', init)
}

function init() {
  initLanguageSwitch()
  initMenu()
  const lightbox = GLightBox({
    dragToleranceY: 30,
    selector: '[data-glightbox]'
  })
}

function initLanguageSwitch() {
  window.document.querySelectorAll('[data-language-switch]').forEach(element => {
    const newLang = element.getAttribute('data-language-switch')

    element.addEventListener('click', () => {
      window.localStorage.setItem('lang', newLang)
      window.location.replace(element.getAttribute(href))
      return false
    })
  })
}

function initMenu() {
  window.document.querySelectorAll('.hamburger, .invisible-overlay').forEach(element => {
    element.addEventListener('click', () => {
      if (document.documentElement.className === 'menu-open') {
        window.document.documentElement.style.height = null
        window.document.documentElement.className = ''
        window.sessionStorage.removeItem('menuOpen')
      } else {
        window.document.documentElement.style.height = `${window.document.body.clientHeight * .4 + 190}px`
        window.document.documentElement.className = 'menu-open'
        window.sessionStorage.setItem('menuOpen', '1')
      }
    })
  })
}
